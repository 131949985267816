import React from 'react';

const MuonLabs = () => {
    return (<svg width="219" height="58" viewBox="0 0 219 58" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.1261 7.49689C5.56049 10.9932 5.62302 23.4496 3.57834 31.6511C1.01469 41.9143 6.2009 45.6826 18.4855 52.1119C32.8487 59.6276 42.3194 47.954 49.1051 26.7404C56.3106 4.21153 37.7451 3.76526 21.1261 7.49689ZM38.0229 27.4485C34.2956 39.1115 29.0875 45.5266 21.1914 41.3984C14.4382 37.8641 11.5887 35.7951 12.9989 30.1552C14.1224 25.6437 14.0864 18.8027 22.6458 16.8809C31.7813 14.833 41.9819 15.0734 38.0214 27.4556L38.0229 27.4485Z" fill="#737373" />
  <path d="M18.9502 29.5529C19.5177 27.2873 19.4997 23.8484 23.7977 22.8875C28.3881 21.8608 33.5103 21.9817 31.5215 28.1969C29.6494 34.0524 27.0304 37.2791 23.0661 35.2043C19.6726 33.4301 18.2458 32.3878 18.9502 29.5529Z" fill="#737373" />
  <path d="M71.3194 38.1849C70.9452 38.1849 70.6358 38.0621 70.3983 37.8165C70.1609 37.5708 70.0314 37.2674 70.0314 36.8917V24.1108C70.0314 23.7207 70.1537 23.4028 70.3983 23.1716C70.643 22.9404 70.9452 22.8175 71.3194 22.8175C71.6935 22.8175 72.0245 22.9331 72.2548 23.1716C72.485 23.4028 72.6074 23.7207 72.6074 24.1108V36.8917C72.6074 37.2674 72.4922 37.578 72.2548 37.8165C72.0245 38.0621 71.7079 38.1849 71.3194 38.1849ZM80.8606 38.1849C80.4865 38.1849 80.177 38.0621 79.9396 37.8165C79.6949 37.5708 79.5726 37.2674 79.5726 36.8917V28.8287C79.5726 27.5932 79.2632 26.6467 78.6372 25.9893C78.0112 25.3318 77.2053 25.0067 76.2195 25.0067C75.1762 25.0067 74.3127 25.3607 73.6291 26.076C72.9456 26.7912 72.6074 27.6944 72.6074 28.7998H70.9308C70.9524 27.6004 71.2042 26.5384 71.7007 25.6136C72.1972 24.6888 72.8736 23.9591 73.7299 23.4389C74.5861 22.9115 75.5575 22.6514 76.6369 22.6514C77.7162 22.6514 78.6732 22.897 79.5007 23.3811C80.3281 23.8652 80.9757 24.5732 81.4435 25.5052C81.9112 26.43 82.1414 27.5426 82.1414 28.8359V36.8989C82.1414 37.2746 82.0263 37.5853 81.7888 37.8237C81.5586 38.0693 81.242 38.1922 80.8534 38.1922L80.8606 38.1849ZM90.3443 38.1849C89.9701 38.1849 89.6607 38.0621 89.4233 37.8165C89.1786 37.5708 89.0563 37.2674 89.0563 36.8917V28.8287C89.0563 27.5932 88.7469 26.6467 88.1209 25.9893C87.4949 25.3318 86.689 25.0067 85.7032 25.0067C84.6598 25.0067 83.8036 25.3679 83.1416 26.0904C82.4796 26.8129 82.1486 27.7449 82.1486 28.8865H79.9684C79.99 27.651 80.2562 26.5673 80.7815 25.628C81.3067 24.6888 82.0335 23.9591 82.9617 23.4389C83.8971 22.9115 84.9477 22.6514 86.1205 22.6514C87.1999 22.6514 88.1569 22.897 88.9843 23.3811C89.8118 23.8652 90.4594 24.5732 90.9271 25.5052C91.3948 26.43 91.6251 27.5426 91.6251 28.8359V36.8989C91.6251 37.2746 91.51 37.5853 91.2725 37.8237C91.0423 38.0693 90.7257 38.1922 90.3371 38.1922L90.3443 38.1849Z" fill="#737373" />
  <path d="M102.872 38.3004C101.569 38.3004 100.404 38.0259 99.3746 37.4695C98.3457 36.9204 97.5398 36.104 96.9425 35.042C96.3453 33.9727 96.0503 32.6722 96.0503 31.1405V24.0601C96.0503 23.7061 96.1726 23.4026 96.4245 23.1498C96.6763 22.8969 96.9785 22.7741 97.3311 22.7741C97.7053 22.7741 98.0147 22.9041 98.2665 23.1498C98.5184 23.3954 98.6407 23.7061 98.6407 24.0601V31.1405C98.6407 32.2243 98.8421 33.1274 99.2595 33.8354C99.6696 34.5507 100.224 35.0781 100.922 35.4249C101.62 35.7717 102.411 35.9451 103.282 35.9451C104.152 35.9451 104.901 35.7789 105.563 35.4393C106.225 35.0998 106.757 34.6518 107.16 34.0738C107.563 33.5031 107.765 32.86 107.765 32.152H109.441C109.405 33.3152 109.103 34.3628 108.534 35.2948C107.966 36.2341 107.189 36.9638 106.196 37.4984C105.21 38.0331 104.095 38.3004 102.864 38.3004H102.872ZM109.053 38.1631C108.678 38.1631 108.369 38.0403 108.132 37.7947C107.887 37.549 107.765 37.2456 107.765 36.8699V24.0601C107.765 23.6844 107.887 23.3737 108.132 23.1353C108.376 22.8969 108.678 22.7668 109.053 22.7668C109.427 22.7668 109.736 22.8897 109.974 23.1353C110.218 23.381 110.341 23.6916 110.341 24.0601V36.8699C110.341 37.2456 110.218 37.5562 109.974 37.7947C109.729 38.0403 109.427 38.1631 109.053 38.1631Z" fill="#737373" />
  <path d="M122.336 38.2715C120.824 38.2715 119.486 37.9392 118.32 37.2745C117.155 36.6098 116.241 35.6922 115.579 34.5218C114.917 33.3514 114.586 32.0075 114.586 30.4903C114.586 28.9731 114.917 27.6003 115.579 26.4299C116.241 25.2595 117.155 24.3419 118.32 23.6772C119.486 23.0125 120.817 22.6802 122.307 22.6802C123.796 22.6802 125.12 23.0125 126.279 23.6772C127.437 24.3419 128.351 25.2595 129.02 26.4299C129.689 27.6003 130.035 28.9514 130.056 30.4903C130.056 32.0075 129.718 33.3514 129.049 34.5218C128.38 35.6922 127.466 36.6098 126.307 37.2745C125.149 37.9392 123.825 38.2715 122.336 38.2715ZM122.307 35.9668C123.328 35.9668 124.235 35.7356 125.019 35.266C125.804 34.7963 126.415 34.1533 126.869 33.3297C127.315 32.5061 127.538 31.5596 127.538 30.4903C127.538 29.421 127.315 28.4746 126.869 27.6365C126.423 26.8056 125.804 26.1554 125.019 25.6857C124.235 25.2161 123.328 24.9849 122.307 24.9849C121.285 24.9849 120.407 25.2161 119.623 25.6857C118.838 26.1554 118.22 26.8056 117.759 27.6365C117.306 28.4673 117.076 29.421 117.076 30.4903C117.076 31.5596 117.306 32.5061 117.759 33.3297C118.212 34.1533 118.838 34.7963 119.623 35.266C120.407 35.7356 121.299 35.9668 122.307 35.9668Z" fill="#737373" />
  <path d="M135.568 38.185C135.194 38.185 134.884 38.0622 134.647 37.8166C134.41 37.5709 134.287 37.2675 134.287 36.8918V24.0892C134.287 23.6991 134.41 23.3812 134.647 23.15C134.892 22.9188 135.194 22.796 135.568 22.796C135.957 22.796 136.273 22.9116 136.503 23.15C136.734 23.3812 136.856 23.6991 136.856 24.0892V36.899C136.856 37.2747 136.741 37.5854 136.503 37.8238C136.266 38.0622 135.957 38.1923 135.568 38.1923V38.185ZM147.29 38.185C146.915 38.185 146.606 38.055 146.354 37.8093C146.102 37.5565 145.973 37.253 145.973 36.899V29.8186C145.973 28.7132 145.764 27.8101 145.361 27.1092C144.951 26.4084 144.404 25.881 143.713 25.5342C143.023 25.1874 142.231 25.014 141.339 25.014C140.497 25.014 139.741 25.1802 139.072 25.5198C138.403 25.8593 137.863 26.3073 137.46 26.8708C137.058 27.4344 136.856 28.0774 136.856 28.8071H135.179C135.201 27.6294 135.496 26.5746 136.072 25.6498C136.647 24.725 137.432 23.9953 138.425 23.4607C139.418 22.926 140.526 22.6587 141.756 22.6587C143.059 22.6587 144.224 22.9332 145.253 23.4896C146.282 24.0386 147.088 24.8551 147.685 25.9171C148.282 26.9864 148.578 28.2869 148.578 29.8186V36.899C148.578 37.253 148.448 37.5565 148.196 37.8093C147.944 38.0622 147.642 38.185 147.29 38.185Z" fill="#737373" />
  <path d="M164.998 38.156C164.213 38.156 163.515 37.9393 162.897 37.513C162.278 37.0867 161.803 36.4871 161.458 35.7285C161.112 34.9698 160.939 34.0956 160.939 33.1058V17.4856C160.939 17.1099 161.055 16.8064 161.292 16.5752C161.522 16.344 161.832 16.2212 162.199 16.2212C162.587 16.2212 162.904 16.3368 163.134 16.5752C163.364 16.8064 163.487 17.1171 163.487 17.4856V33.0986C163.487 33.8283 163.623 34.428 163.904 34.8976C164.185 35.3672 164.544 35.5984 164.998 35.5984H165.696C166.048 35.5984 166.329 35.714 166.538 35.9524C166.746 36.1836 166.847 36.5015 166.847 36.8917C166.847 37.2818 166.696 37.5708 166.386 37.802C166.077 38.0332 165.681 38.156 165.199 38.156H165.005H164.998Z" fill="#737373" />
  <path d="M175.374 38.2715C173.992 38.2715 172.754 37.932 171.654 37.2456C170.553 36.5592 169.682 35.6344 169.034 34.464C168.394 33.2936 168.07 31.9714 168.07 30.4903C168.07 29.0092 168.408 27.6798 169.092 26.5022C169.776 25.3245 170.697 24.3925 171.862 23.7061C173.028 23.0197 174.337 22.6802 175.791 22.6802C177.244 22.6802 178.576 23.0197 179.734 23.7061C180.893 24.3925 181.806 25.3173 182.49 26.5022C183.174 27.687 183.519 29.0092 183.541 30.4903L182.533 30.7432C182.533 32.1665 182.224 33.4453 181.598 34.5796C180.972 35.7139 180.123 36.6098 179.036 37.2745C177.957 37.9392 176.734 38.2715 175.374 38.2715ZM175.791 35.9668C176.798 35.9668 177.691 35.7284 178.475 35.2515C179.259 34.7747 179.878 34.1172 180.331 33.2863C180.785 32.4555 181.015 31.5235 181.015 30.4903C181.015 29.4571 180.785 28.5035 180.331 27.6654C179.871 26.8345 179.252 26.177 178.475 25.7002C177.691 25.2233 176.806 24.9849 175.82 24.9849C174.834 24.9849 173.935 25.2233 173.136 25.7002C172.337 26.177 171.704 26.8273 171.251 27.6509C170.797 28.4746 170.567 29.421 170.567 30.4903C170.567 31.5596 170.797 32.4555 171.251 33.2863C171.704 34.1172 172.33 34.7747 173.121 35.2515C173.913 35.7284 174.805 35.9668 175.791 35.9668ZM182.224 38.1848C181.85 38.1848 181.54 38.062 181.303 37.8164C181.065 37.5707 180.943 37.2673 180.943 36.8916V32.3977L181.475 29.7028L183.548 30.4903V36.8916C183.548 37.2673 183.425 37.5779 183.181 37.8164C182.936 38.0548 182.62 38.1848 182.231 38.1848H182.224Z" fill="#737373" />
  <path d="M195.485 38.2716C194.032 38.2716 192.722 37.932 191.556 37.2601C190.391 36.5882 189.47 35.6562 188.8 34.4785C188.131 33.3009 187.786 31.9787 187.764 30.5193V17.5145C187.764 17.1243 187.887 16.8064 188.124 16.5752C188.369 16.344 188.671 16.2212 189.045 16.2212C189.434 16.2212 189.75 16.3368 189.981 16.5752C190.211 16.8064 190.333 17.1243 190.333 17.5145V25.3824C190.988 24.5587 191.787 23.9013 192.736 23.4172C193.686 22.9331 194.751 22.6875 195.924 22.6875C197.306 22.6875 198.543 23.0271 199.644 23.7134C200.745 24.3998 201.616 25.3246 202.256 26.495C202.896 27.6655 203.22 28.9876 203.22 30.4687C203.22 31.9498 202.882 33.2792 202.198 34.4569C201.515 35.6345 200.594 36.5665 199.428 37.2529C198.263 37.9393 196.946 38.2788 195.471 38.2788L195.485 38.2716ZM195.485 35.9669C196.471 35.9669 197.363 35.7284 198.155 35.2516C198.946 34.7748 199.572 34.1173 200.033 33.2864C200.486 32.4556 200.716 31.5091 200.716 30.4615C200.716 29.4139 200.486 28.4746 200.033 27.651C199.572 26.8274 198.953 26.1771 198.155 25.7003C197.363 25.2234 196.471 24.985 195.485 24.985C194.499 24.985 193.607 25.2234 192.816 25.7003C192.024 26.1771 191.405 26.8274 190.959 27.651C190.513 28.4746 190.29 29.4139 190.29 30.4615C190.29 31.5091 190.513 32.4483 190.959 33.2864C191.405 34.1173 192.024 34.7748 192.816 35.2516C193.607 35.7284 194.499 35.9669 195.485 35.9669Z" fill="#737373" />
  <path d="M212.97 38.2717C211.74 38.2717 210.574 38.0766 209.473 37.6937C208.372 37.3108 207.509 36.7833 206.868 36.1042C206.624 35.8224 206.523 35.5118 206.559 35.1794C206.595 34.8398 206.768 34.5508 207.063 34.3052C207.379 34.0596 207.703 33.9584 208.041 33.9945C208.379 34.0307 208.66 34.1824 208.883 34.4425C209.257 34.8543 209.804 35.2083 210.516 35.5118C211.236 35.8152 212.02 35.9597 212.884 35.9597C214.093 35.9597 214.992 35.7574 215.582 35.3528C216.172 34.9482 216.474 34.4497 216.489 33.85C216.489 33.2287 216.201 32.7157 215.618 32.2895C215.042 31.8704 214.021 31.5381 212.567 31.2924C210.682 30.9384 209.315 30.3821 208.459 29.6379C207.602 28.8865 207.171 27.9834 207.171 26.9141C207.171 25.9604 207.437 25.1657 207.969 24.5371C208.502 23.9086 209.2 23.4389 210.07 23.1355C210.941 22.832 211.855 22.6731 212.826 22.6731C214.057 22.6731 215.136 22.8754 216.071 23.28C217 23.6846 217.748 24.2409 218.309 24.9489C218.554 25.2307 218.662 25.5197 218.633 25.8232C218.604 26.1266 218.453 26.3722 218.173 26.5818C217.892 26.7479 217.575 26.8057 217.208 26.7479C216.841 26.6901 216.539 26.5312 216.302 26.2711C215.834 25.787 215.316 25.4402 214.747 25.2452C214.179 25.0501 213.524 24.9489 212.776 24.9489C211.884 24.9489 211.143 25.1151 210.552 25.4547C209.962 25.7943 209.675 26.2494 209.675 26.8274C209.675 27.2031 209.775 27.5282 209.97 27.81C210.164 28.0918 210.531 28.3519 211.078 28.5831C211.617 28.8143 212.395 29.031 213.402 29.2116C214.798 29.4717 215.906 29.8185 216.719 30.252C217.532 30.6855 218.115 31.1913 218.468 31.7693C218.82 32.3472 219 33.0047 219 33.7344C219 34.5942 218.763 35.3673 218.288 36.0536C217.813 36.74 217.129 37.2746 216.23 37.672C215.337 38.0622 214.251 38.2645 212.985 38.2645L212.97 38.2717Z" fill="#737373" />
  <path d="M97.3315 44.2684C96.9573 44.2684 96.6479 44.1455 96.4104 43.8999C96.1658 43.6542 96.0435 43.3508 96.0435 42.9751V30.1653C96.0435 29.7896 96.1658 29.479 96.4104 29.2405C96.6551 29.0021 96.9573 28.8721 97.3315 28.8721C97.7056 28.8721 98.015 28.9949 98.2525 29.2405C98.4971 29.4862 98.6194 29.7969 98.6194 30.1653V42.9751C98.6194 43.3508 98.4971 43.6615 98.2525 43.8999C98.0078 44.1455 97.7056 44.2684 97.3315 44.2684Z" fill="#737373" />
</svg>
)
}

export default MuonLabs