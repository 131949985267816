import * as THREE from 'three';
import React, { Suspense, useCallback, useRef } from 'react';
import { Canvas, extend } from '@react-three/fiber';
import * as meshline from './MeshLine';
import { Effects } from './Effects';
import { Scene } from './Scene';
import CosmwanderLogo from './CosmWander';
import TwitterIcon from './Icons/TwitterIcon';
import TelegramIcon from './Icons/TelegramIcon';
import MuonLabs from './Icons/MuonLabs';
import './styles.css';
import Spinner from './Spinner';

extend(meshline);

export function App() {
  const mouse = useRef([0, 0]);

  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  );

  return (
    <div onMouseMove={onMouseMove} style={{ width: '100vw', height: '100vh' }}>
      <div className='logo'>
      <CosmwanderLogo />
      </div>
      <Suspense fallback={<Spinner />}>
      <Canvas
        pixelRatio={window.devicePixelRatio}
        camera={{ fov: 100, position: [0, 0, 30] }}
        onCreated={({ gl, size, camera }) => {
          if (size.width < 600) {
            camera.position.z = 45;
          }
          gl.setClearColor(new THREE.Color('#020207'));
        }}>
        <Scene mouse={mouse} />
        <Effects />
      </Canvas>

      
        <div className="overlay">
              <div className="soon">Coming Soon</div>
        </div>
     

      <div className="attribution">
          <MuonLabs />
        <div className='social'>
          <a target="_blank" href="https://twitter.com/cosmwander"><TwitterIcon /></a> {' '}
          <a target="_blank" href="https://t.me/cosmwander"><TelegramIcon /></a>
        </div>
        </div>
        </Suspense>
    </div>
  );
}
