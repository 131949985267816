import { OrbitControls, CameraShake } from '@react-three/drei';
import { Sparks } from './Sparks';
import { SpaceDust } from './SpaceDust';
import { Planet } from './Planet';
import { SpaceShip } from './SpaceShip';

const colors = {
  malevolentIllusion: [
    '#c06995',
    '#de77c7',
    '#df86df',
    '#d998ee',
    '#ceadf4',
    '#c6bff9',
  ],
  sunnyRainbow: [
    '#fbe555',
    '#fb9224',
    '#f45905',
    '#be8abf',
    '#ffeed0',
    '#feff89',
  ],
};


export function Scene({ mouse }) {

  return (
    <>
      <OrbitControls
        makeDefault
        enablePan={false}
        enableRotate={false}
        enableZoom={false}
      />
      <CameraShake
        yawFrequency={0.05 * 1}
        rollFrequency={0.2 * 1}
        pitchFrequency={0.1 * 1}
      />
      <pointLight distance={100} intensity={4} color="white" />
      <group>
        <SpaceShip />
        <Planet distortionScale={100} />
        <SpaceDust count={5000} mouse={mouse} />
        <Sparks count={20} mouse={mouse} colors={colors.malevolentIllusion} />
      </group>
    </>
  );
}
